import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid, Paper, Fade, Popover } from "@material-ui/core";
import about_me from "../images/aboutmepic.jpg";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "url(afterglow.jpg) no-repeat center center fixed",
  },

  heading: {
    fontFamily: "Trebuchet MS",
    color: "white",
    padding: "5rem 0 1rem 0rem",
    textTransform: "uppercase",
  },

  titles: {
    color: "pink",
    opacity: "1",
    fontFamily: "Trebuchet MS",
  },

  bodyContainer: {
    margin: "auto auto 5rem auto",
    background: "#000000",
    height: "100%",
    width: "80%",
    opacity: "0.9",
    padding: "3rem 3rem 3rem 3rem",
  },
  body: {
    color: "black",
    padding: "3rem 3rem 3rem 3rem",
    textTransform: "none",
    fontFamily: "Trebuchet MS",
  },

  subHeading: {
    color: "white",
    padding: "0",
    textTransform: "uppercase",
  },
  paperStyle: {
    height: "100%",
    width: "100%",
  },
  imgStyle: {
    height: "550px",
    width: "350px",
  },
  gridStyle: {
    alignContent: "center",
  },
}));

const AboutMe = () => {
  const classes = useStyles();

  return (
    <>
      <Box component="header" className={classes.mainContainer}>
        <Typography variant="h4" align="center" className={classes.heading}>
          About Me
        </Typography>
      </Box>
      <Box component="body" className={classes.bodyContainer}>
        <Grid
          className={classes.gridStyle}
          container
          direction="row"
          justify="center"
          spacing={0}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper className={classes.paperStyle}>
              <Typography className={classes.body}>
                Hey there! I'm Selena and I am a recent graduate from Kennesaw
                State University. I initially chose computer science as a major
                because I was very into video games and wanted to program one of
                my own!
                <br></br>
                <br></br>
                Eventually, I ended up enjoying different branches of the field
                and am aiming to work as a Full Stack Developer someday. As of
                right now, I am mainly experimenting with a variety of web
                development tools in order to broaden my skillset. For example,
                this website was mainly built using React (because it sounded
                cool), JSX, Material-UI, and some libraries that provided the
                special effects. <br></br>
                <br></br>
                Some other things I enjoy are playing guitar, writing roleplay,
                cosplaying, going to conventions, and hanging out with dogs!
                (One of my major goals in life is to adopt at least five dogs).
              </Typography>
            </Paper>
          </Grid>
          {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <img src={about_me} className={classes.imgStyle} />
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default AboutMe;
