import React from "react";
import { Tabs, Tab, AppBar, Fade } from "@material-ui/core";
import Header from "./Header";
import Particles from "react-particles-js";
import { makeStyles } from "@material-ui/styles";
import Resume from "./Resume";
import Projects from "./Projects";
import AboutMe from "./AboutMe";

const useStyles = makeStyles({
  particlesCanva: {
    position: "absolute",
    opacity: "0.3",
  },
  appBarStyle: {
    position: "fixed",
    top: "0",
    background: "#000000",
    width: "100%",
    opacity: "1",
  },
  indicator: {
    backgroundColor: "pink",
    textEmphasisColor: "#000000",
  },
});

const Home = (props) => {
  const classes = useStyles();

  const { match, history } = props;
  const { params } = match;
  const { page } = params;

  // Having them separate makes it so that they have constant lookup
  const tabNameToIndex = {
    0: "home",
    1: "about_me",
    2: "resume",
    3: "projects",
  };

  // This one sets the url equal to the specified tab

  const indexToTabName = {
    home: 0,
    aboutme: 1,
    resume: 2,
    projects: 3,
  };

  const [selectedTab, setSelectedTab] = React.useState(indexToTabName[page]);
  const [choice, setChoice] = React.useState(false);

  const handleChange = (event, newValue) => {
    history.push(`/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
    setChoice(choice === true);
  };

  return (
    <>
      <Particles
        canvasClassName={classes.particlesCanva}
        params={{
          particles: {
            number: {
              value: 100,
              density: {
                enable: true,
                value_area: 900,
              },
            },
            color: {
              value: "#ffffff",
            },
            line_linked: {
              enable: false,
            },
            shape: {
              type: "circle",
              stroke: {
                width: 2,
                color: "#ffffff",
              },
            },
            size: {
              value: 1,
              random: true,
              anim: {
                enable: true,
                speed: 30,
                size_min: 0.1,
                sync: false,
              },
            },
            opacity: {
              value: 1,
              random: true,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.9,
                sync: false,
              },
            },
          },
          interactivity: {
            onhover: {
              enable: true,
              mode: "repulse",
            },
          },
        }}
      />
      <AppBar position="static" className={classes.appBarStyle}>
        <Tabs
          centered
          value={selectedTab}
          onChange={handleChange}
          classes={{ indicator: classes.indicator }}
        >
          <Tab label="Home" />
          <Tab label="About Me" />
          <Tab label="Resume" />
          <Tab label="Projects" />
        </Tabs>
      </AppBar>
      {selectedTab === 0 && (
        <Fade in={choice} timeout={4000}>
          <Header />
        </Fade>
      )}
      {selectedTab === 1 && <AboutMe />}
      {selectedTab === 2 && <Resume />}
      {selectedTab === 3 && <Projects />}
    </>
  );
};

export default Home;
