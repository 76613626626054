import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Avatar, Grid, Box } from "@material-ui/core";
import Typed from "react-typed";
import Footer from "./Footer";
import profile_picture from "../images/avatar.png";

//CSS Styles
const useStyles = makeStyles((theme) => ({
  avatar: {
    opacity: "0.6",
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: theme.spacing(1),
  },

  title: {
    color: "pink",
    opacity: "1",
  },
  subtitle: {
    color: "white",
    marginBottom: "3rem",
  },
  typedContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    textAlign: "center",
    zIndex: 1,
  },
}));
const Header = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.typedContainer}>
        <Grid container justify="center">
          <Avatar
            className={classes.avatar}
            src={profile_picture}
            alt="selena"
          />
        </Grid>
        {/* <Typography className={classes.title} variant="h4">
          <Typed strings={["Selena Guillen"]} typeSpeed={40} />
        </Typography> */}

        <br />
        <Typography className={classes.subtitle} variant="h5">
          <Typed
            strings={[
              "Computer Science",
              "Javascript, HTML, CSS",
              "Python",
              "Java",
              "C#",
            ]}
            typeSpeed={40}
            backspeed={60}
            loop
          />
        </Typography>
        <Footer />
      </Box>
    </>
  );
};

export default Header;
