import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core";
import { positions } from "@material-ui/system";

import project_img from "../images/halloween_header.png";
import P1 from "../images/A_Puzzle Solver_header.png";
import P2 from "../images/SP_header.png";
import P3 from "../images/Simple_Paint_header.png";
import P4 from "../images/WS_header.png";

const useStyles = makeStyles({
  mainContainer: {
    background: "url(afterglow.jpg) no-repeat center center fixed",
    height: "100%",
  },
  heading: {
    fontFamily: "Trebuchet MS",
    color: "white",
    padding: "5rem 0",
    textTransform: "uppercase",
  },
  Card: {
    opacity: "0.85",
    maxWidth: "80%",
    maxHeight: "100%",
    margin: "auto auto 5rem auto",
  },
  media: {
    height: 500,
    width: "100%",
  },
});

const Projects = () => {
  const classes = useStyles();
  return (
    <>
      <Box component="header" className={classes.mainContainer}>
        <Typography variant="h4" align="center" className={classes.heading}>
          Projects
        </Typography>
      </Box>
      <Box component="div" className={classes.mainContainer}>
        <Grid container justify="center">
          {/* Project 1 */}
          <Grid item xs={12} sm={8} md={6}>
            <Card className={classes.Card}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Project 1"
                  height="140"
                  image={P1}
                  className={classes.Media}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                    A * 8 Puzzle Solver
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    fontFamily="Trebuchet MS"
                  >
                    The 8-Puzzle problem is a problem that is played on a 3-by-3
                    grid with 8 blocks labled 1-8 and a blank space. The A *
                    Algorithm solves this classic puzzle by calculating an
                    estimated distance and taking the path that costs the least
                    in order to achieve the goal state.
                  </Typography>
                </CardContent>
              </CardActionArea>

              <CardActions>
                <Button
                  target="_blank"
                  href="https://github.com/SelenaGuillen/A-Star-8-Puzzle"
                  size="small"
                  color="primary"
                >
                  GitHub
                </Button>
                <Button
                  target="_blank"
                  href="https://youtu.be/v6tpgmygwMA"
                  size="small"
                  color="primary"
                >
                  Demo
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Project 2 */}
          <Grid item xs={12} sm={8} md={6}>
            <Card className={classes.Card}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Project 2"
                  height="140"
                  image={P2} //change this to the GUI later when u revisit
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                    Attacks on Image Scaling Algorithms
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    The main focus was to recreate the scaling attacks as
                    described by Qixue Xiao’s research. The attempted way to
                    achieve this was to create an "attack image" from two,
                    different images: the source and the target image. These two
                    images are merged together using a Python exension (dccp) in
                    order to craft the attack image.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  target="_blank"
                  href="https://github.com/MichaelHandlin/ScalingAttackSeniorProject"
                  size="small"
                  color="primary"
                >
                  GitHub
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Project 3 */}
          <Grid item xs={12} sm={8} md={6}>
            <Card className={classes.Card}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Project 3"
                  height="140"
                  image={P3}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                    Simple Paint Application
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    This Paint application functions similar to Microsoft Paint
                    with the ability to select brush thickness, shapes, and
                    color. It was developed using C# and Forms.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  target="_blank"
                  href="https://github.com/SelenaGuillen/Simple-Paint-Application"
                  size="small"
                  color="primary"
                >
                  GitHub
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Project 4 */}
          <Grid item xs={12} sm={8} md={6}>
            <Card className={classes.Card}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Project 4"
                  height="140"
                  image={P4}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                    This Website!
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    This website was built using JSX, React, Material-UI,
                    Particles.js, react-router, and react-typed.
                  </Typography>
                </CardContent>
              </CardActionArea>
              {/* <CardActions>
                <Button size="small" color="primary">
                  GitHub
                </Button>
              </CardActions> */}
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Projects;
