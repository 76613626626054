import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Collapse,
  Paper,
  Button,
  Grid,
} from "@material-ui/core";
import resume_doc from "../Resume.pdf";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "url(afterglow.jpg) no-repeat center center fixed",
  },

  heading: {
    fontFamily: "Trebuchet MS",
    color: "white",
    padding: "5rem 0 1rem 0rem",
    textTransform: "uppercase",
  },

  titles: {
    color: "pink",
    opacity: "1",
    fontFamily: "Trebuchet MS",
  },

  bodyContainer: {
    margin: "auto auto 5rem auto",
    background: "#000000",
    height: "100%",
    width: "70%",
    opacity: "0.9",
    padding: "2rem 2rem 2rem 2rem",
  },
  body: {
    color: "white",
    padding: "3rem 0",
    textTransform: "none",
  },

  subHeading: {
    color: "white",
    padding: "0",
    textTransform: "uppercase",
  },
  buttonStyle: {
    fontStyle: "italic",
    fontFamily: "Trebuchet MS",
    padding: "0rem 1rem 0rem 1rem",
    color: "white",
    "&:hover": {
      color: "pink",
      transition: "0.3s ease-out",
    },
  },
  paperStyle: {
    height: "50%",
    width: "30%",
  },

  boxStyle: {
    margin: "auto auto 5rem auto", //maybe make this fixed so it doesn't change size, make scrollable?
    background: "white",
    height: "80%",
    width: "80%",
    padding: "0rem 3rem 3rem 3rem",
  },
  descriptionFont: {
    padding: "5rem 3rem 3rem 3rem",
    fontFamily: "Trebuchet MS",
  },
  gridStyle: {
    alignContent: "left",
  },
  a: {
    color: "#0060B6",
    textDecoration: "none",
  },
}));

const Resume = () => {
  const classes = useStyles();
  const [showEducation, setShowEducation] = React.useState(false);
  const [showSkills, setShowSkills] = React.useState(false);

  return (
    <>
      <Box component="header" className={classes.mainContainer}>
        <Typography variant="h4" align="center" className={classes.heading}>
          Resume
        </Typography>
      </Box>
      <Box component="body" className={classes.bodyContainer}>
        <Grid
          className={classes.gridStyle}
          container
          direction="column"
          spacing={0}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              className={classes.buttonStyle}
              onClick={() =>
                setShowEducation(!showEducation) && setShowSkills(false)
              }
            >
              Education
            </Button>
            <br></br>
            <br></br>

            {showEducation && (
              <Collapse in={true} timeout="5">
                <Paper
                  elevation={4}
                  component="body"
                  className={classes.boxStyle}
                >
                  <Typography
                    align="center"
                    className={classes.descriptionFont}
                  >
                    Kennesaw State University / Southern Polytechnic State
                    University
                    <br></br>
                    College of Computing and Software Engineering<br></br>
                    Bachelor of Science<br></br>
                    Computer Science
                  </Typography>
                </Paper>
              </Collapse>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              className={classes.buttonStyle}
              onClick={() =>
                setShowSkills(!showSkills) && setShowEducation(false)
              }
            >
              Skills
            </Button>
            <br></br>
            <br></br>
            {showSkills && (
              <Paper component="body" className={classes.boxStyle}>
                <Typography align="center" className={classes.descriptionFont}>
                  C#, Java, Python, Javascript, HTML, CSS, SQL, C++
                </Typography>
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <a
              className={classes.a}
              href={resume_doc}
              target="_blank"
              rel="noopener noreferrer"
              download="SelenaGuillen_Resume"
            >
              <Button className={classes.buttonStyle}>
                Download PDF Version
              </Button>
            </a>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Resume;
