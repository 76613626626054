import React from "react";
import "./App.css";
import Home from "./components/Home.jsx";
import { Route, Switch, Redirect } from "react-router-dom";

export default function App() {
  return (
    <Switch>
      {/* This will automatically redirect them to about if someoen types /home  */}
      <Redirect exact from="/" to="/home" />
      <Route exact path="/:page?" render={(props) => <Home {...props} />} />
    </Switch>
  );
}
