import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { Link } from "react-router-dom";
import LinkedIn from "@material-ui/icons/LinkedIn";
import GitHub from "@material-ui/icons/GitHub";
import Email from "@material-ui/icons/Email";

const useStyles = makeStyles({
  root: {
    "& .MuiBottomNavigationAction-root": {
      minWidth: 0,
      maxWidth: 250,
    },
    "& .MuiSvgIcon-root": {
      opacity: "0.5",
      fill: "white",
      fontSize: "2rem",
      "&:hover": {
        fill: "pink",
        fontSize: "3rem",
        transition: "0.2s ease-out",
      },
    },
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <BottomNavigation width="auto" style={{ background: "none" }}>
        <a target="_blank" href="https://www.linkedin.com/in/selena-guillen/">
          <BottomNavigationAction
            className={classes.root}
            style={{ padding: 0 }}
            icon={<LinkedIn />}
          />
        </a>
        <a target="_blank" href="https://github.com/SelenaGuillen">
          <BottomNavigationAction
            className={classes.root}
            style={{ padding: 0 }}
            icon={<GitHub />}
          />
        </a>
        <a
          target="_blank"
          href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=selenaguillen72@gmail.com"
        >
          <BottomNavigationAction
            className={classes.root}
            style={{ padding: 0 }}
            icon={<Email />}
          />
        </a>
      </BottomNavigation>
    </>
  );
};

export default Footer;
